import moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
  // q: how are you
} from "@mui/material";
import { useSnackbar } from "notistack";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { generateNumber } from "../../utils/utils";
import { BASE_URL, MAX_COLS, DOMAIN, ID_BASE } from "../../global";
import { ClassicTable } from "../../utils/components/Styles";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import { Autocomplete } from "@mui/material";

import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";

import "./certificate.css";
import "./certificateList.css";
import { countOfCertificatesAnalytics } from "../../utils/analytics/certificateAnalytics";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const TableCell = withStyles({
  root: {
    border: "none",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "14px",
    paddingLeft: "5px",
  },
})(MuiTableCell);

const BlackBorderTableCell = withStyles({
  root: {
    border: "1px solid black !important",
    borderBottomWidth: "0px !important",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "12px",
    paddingLeft: "5px",
  },
})(MuiTableCell);

const useStyles = makeStyles({
  table: {
    border: "1px solid black",
    padding: "1px",
  },
  infoCell: {
    maxHeight: "5px",
  },
  innerCell: {
    padding: "0px",
    maxWidth: "40%",
  },
  formatedCell: {
    fontSize: "15px",
    padding: "0px",
    margin: "0px",
  },
  certificateCell: {
    fontSize: "15px",
    padding: "0px",
    margin: "0px",
    width: "20%",
  },
  sxPad: {
    padding: "5px",
  },
  addressCell: {
    width: "200px",
  },
});
export default function EditCertificate(props) {
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { certificateId: paramCertificateId } = useParams();
  const certificateId = props.certificateIds || paramCertificateId;
  // const { certificateId } = useParams();
  const [staticReadingRows, setStaticReadingRows] = React.useState([]);
  const [staticCertificateReadings, setStaticCertificateReadings] =
    React.useState([]);

  const [instrument, setInstrument] = React.useState(null);
  const [tables, setTables] = React.useState(null);
  const [staticTables, setStaticTables] = React.useState(null);
  const [configuration, setConfiguration] = React.useState({});
  const [clientId, setClientId] = React.useState("");
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [standardMasterArray, setStandardMasterArray] = React.useState([]);
  const [instrumentName, setInstrumentName] = React.useState("");
  const [requestedName, setRequestedName] = React.useState("");
  const [make, setMake] = React.useState("");
  const [calibrationProcedureNo, setCalibrationProcedureNo] =
    React.useState("");
  const [referenceStandards, setReferenceStandards] = React.useState("");
  const [lc, setLC] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [ranges, setRanges] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [calibrationDate, setCalibrationDate] = React.useState(null);
  const [dcDate, setDcDate] = React.useState(null);
  const [nextDueDate, setNextDueDate] = React.useState(null);
  const [receiptDate, setReceiptDate] = React.useState(null);
  const [poDate, setPoDate] = React.useState(null);
  const [dateOfIssue, setDateOfIssue] = React.useState("");
  const [calibrationFrequency, setCalibrationFrequency] = React.useState(null);

  const [calProcRefNo, setCalProcRefNo] = React.useState("");
  const [mfgNo, setMfgNo] = React.useState("");
  
  const [specificValuesFromTable, setSpecificValuesFromTable] = React.useState(
    []
  );
  const [status, setStatus] = React.useState(-1);
  const [identificationNo, setIdentificationNo] = React.useState("");
  const [DUCID, setDUCID] = React.useState([]);
  const [calType, setCalType] = React.useState("");
  const [tableName, setTableName] = React.useState("");
  const [unitName, setUnitName] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [accuracy, setAccuracy] = React.useState("");
  const [filterRangeData, setFilterRangeData] = React.useState([]);
  const [serialNo, setSerialNo] = React.useState("");
  const [selectedStandards, setSelectedStandards] = React.useState("");
  const [ulrNumber, setUlrNumber] = React.useState(null);
  const [certificateNumber, setCertificateNumber] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [jobNumber, setJobNumber] = React.useState("");
  const [workOrderNumber, setWorkOrderNumber] = React.useState("");
  const [poNo, setPoNo] = React.useState(null);
  const [startTemp, setStartTemp] = React.useState("");
  const [endTemp, setEndTemp] = React.useState("");
  const [tempDiff, setTempDiff] = React.useState("");
  const [startHumidity, setStartHumidity] = React.useState("");
  const [endHumidity, setEndHumidity] = React.useState("");
  const [humDiff, setHumDiff] = React.useState("");
  const [models, setModel] = React.useState("");
  const [ConOfDuc, setConOfDuc] = React.useState("");
  const [calPoint, setCalPoint] = React.useState("");
  const [calMethod, setCalMethod] = React.useState("");
  const [locationOfInstrument, setLocationOfInstrument] = React.useState("");
  const [srnNo, setSrnNo] = React.useState("");
  const [atmosphericPressure, setAtmosphericPressure] = React.useState("");
  const [disciplineName, setDisciplineName] = React.useState("");
  const [defaultReamrk, setDefaultRemark] = React.useState(null);
  const userType = localStorage.getItem("type");
  const [tableDisplayStatus, setTableDisplayStatus] = React.useState(1);
  const [settingList, setSettingList] = React.useState({});
  // const [isPrinting, setisPrinting] = React.useState(false);
  const [isPrinting, setisPrinting] = React.useState(!!props.certificateIds);
  const [id, setId] = React.useState(-1);
  const [approvedby, setApprovedby] = React.useState("");
  const [calibratedby, setCalibratedby] = React.useState("");
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [extraColumns, setExtraColumns] = React.useState([]);
  const [departmet, setDepartment] = React.useState("");
  const [input, setInput] = React.useState("");
  const [operatingrange, setOperatingrand] = React.useState("");
  const [revisionNumber, setRevisionNumber] = React.useState("");
  const [amendmentHistory, setAmendmentHistory] = React.useState("");
  const [calibrationReason, setCalibrationReason] = React.useState(1);
  const [selectedRangeIds, setSelectedRangeIds] = React.useState([]);
  const [complianceStatus, setComplianceStatus] = React.useState(null);
  const [compliance, setCompliance] = React.useState(false);
  const [lastModified, setLastModified] = React.useState("");
  const [showAmendment, setShowAmendment] = React.useState(false);
  // utils
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  // api calls
  function fetchSettings() {
    axiosWithToken.get(BASE_URL + `settings`).then((res) => {
      let remark = null,
        complance = false,
        settings = res.data;
      let _settingList = {};
      settings.forEach((setting) => {
        if (setting?.keyName?.toLowerCase() === "remarks") {
          remark = setting.value;
        } else if (
          setting.keyName === "Compliance Status" &&
          setting.value.toLowerCase() === "true"
        ) {
          complance = true;
        }
        _settingList[setting.keyName] = setting.value;
      });
      _settingList["amendment"] = _settingList["amendment"].toLowerCase();
      setSettingList(_settingList);
      // setDefaultRemark(remark);
      setCompliance(complance);
    });
  }

  const parseRange = (rangeVal) => {
    let tmp = rangeVal
      ? rangeVal.split("||").map((e) => {
          let tmp2 = e
            .trim()
            .split("|")
            .map((e2) => e2.trim());
          let tmpUnit = tmp2[0].split("#").map((e2) => e2.trim());
          tmpUnit = tmpUnit[1] ? tmpUnit[1] : "";
          tmp2[0] = tmp2[0].split("#")[0].trim();
          if (tmp2[1]) {
            tmp2[1] = tmp2[1].split("#")[0].trim();
          }
          tmp2 = tmp2.join(" to ");
          return `${tmp2}${tmpUnit.length > 0 ? ` ${tmpUnit}` : ""}`;
        })
      : [];
    return tmp.join(" , ");
  };

  function fetchCertificate() {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `xjoin?_join=cer.certificates,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(cer.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cer.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=cer.lastModified,cer.approvedby,cer.status,cer.clientId,cer.location,cer.id,cer.instrumentId,cer.lc,cer.make,cer.model,cer.ConOfDuc,cer.ranges,cer.mfgNo,cer.deletedTableIds,cust.companyName,ins.instrumentName,ins.standardMasterIds,ins.calibrationProcedureNo,ins.referenceStandards,cust.address,cer.dcNumber,cer.calibrationDate,cer.dcDate,cer.nextDueDate,cer.receiptDate,cer.calProcRefNo,cer.mfgNo,cer.identificationNo,cer.DUCID,cer.DUCID,cer.calibrationType,cer.specificValues,cer.locationOfInstrument,cer.serialNumber,cer.additionalStandardIds,cer.serviceReqNumber,cer.jobNumber,cer.accuracy,cer.poNumber,cer.poDate,cer.startTemp,cer.endTemp,cer.tempDiff,cer.startHumidity,cer.endHumidity,cer.humDiff,cer.dateOfIssue,cer.model,cer.calPoint,cer.calMethod,cer.locationOfInstrument,cer.srnNo,cer.atmosphericPressure,cer.configuration,cer.workOrderNumber,cer.certificateNumber,cer.ULRNo,cer.tableDisplayStatus,disc.name,disc.remarks,cer.extraColumns,cer.calibrationReason,cer.complianceStatus&_where=(cer.id,eq, ${certificateId})`
      )
      .then((res) => {
        if (res.data.length && res.data[0] != null) {
          setLastModified(res.data[0].cer_lastModified);
          setApprovedby(
            res.data[0].cer_approvedby ? res.data[0].cer_approvedby : ""
          );
          setStatus(res.data[0].cer_status);
          setId(res.data[0].cer_id);
          setClientId(res.data[0].cust_companyName);
          setInstrumentName(res.data[0].ins_instrumentName);
          setMake(res.data[0].cer_make);
          setModel(res.data[0].cer_model);
          setLC(res.data[0].cer_lc);
          setDefaultRemark(res.data[0].disc_remarks);
          setRanges(parseRange(res.data[0].cer_ranges));
          setReferenceStandards(res.data[0].ins_referenceStandards);
          setSelectedStandards(
            res.data[0].cer_additionalStandardIds?.split(",")
          );
          setCalibrationProcedureNo(res.data[0].ins_calibrationProcedureNo);
          setAddress(res.data[0].cust_address);
          setDcNumber(res.data[0].cer_dcNumber);
          setUlrNumber(res.data[0].cer_ULRNo);
          setCertificateNumber(res.data[0].cer_certificateNumber);
          // setCalibrationDate(res.data[0].cer_calibrationDate)
          setDcDate(res.data[0].cer_dcDate);
          setNextDueDate(res.data[0].cer_nextDueDate);
          setReceiptDate(res.data[0].cer_receiptDate);
          setCalProcRefNo(res.data[0].cer_calProcRefNo);
          setMfgNo(res.data[0].cer_mfgNo);
          setIdentificationNo(res.data[0].cer_identificationNo);
          setDUCID(res.data[0].cer_DUCID);
          setCalType(res.data[0].cer_calibrationType);
          res.data[0].cer_specificValues
            ? setSpecificValuesFromTable(
                res.data[0].cer_specificValues.split(",")
              )
            : setSpecificValuesFromTable([]);
          setLocation(res.data[0].cer_location);
          setAccuracy(res.data[0].cer_accuracy);
          setSerialNo(res.data[0].cer_serialNumber);
          setServiceReqNumber(res.data[0].cer_serviceReqNumber);
          setJobNumber(res.data[0].cer_jobNumber);
          setWorkOrderNumber(res.data[0].cer_workOrderNumber);
          setPoNo(res.data[0].cer_poNumber);
          setPoDate(res.data[0].cer_poDate);
          setStartTemp(res.data[0].cer_startTemp);
          setEndTemp(res.data[0].cer_endTemp);
          setTempDiff(res.data[0].cer_tempDiff);
          setStartHumidity(res.data[0].cer_startHumidity);
          setEndHumidity(res.data[0].cer_endHumidity);
          setHumDiff(res.data[0].cer_humDiff);
          setDateOfIssue(res.data[0].cer_dateOfIssue);
          setModel(res.data[0].cer_model);
          setConOfDuc(res.data[0].cer_ConOfDuc);
          setCalPoint(res.data[0].cer_calPoint);
          setCalMethod(res.data[0].cer_calMethod);
          setLocationOfInstrument(res.data[0].cer_locationOfInstrument);
          setSrnNo(res.data[0].cer_srnNo);
          setAtmosphericPressure(res.data[0].cer_atmosphericPressure);
          setConfiguration(JSON.parse(res.data[0].cer_configuration));
          setDisciplineName(res.data[0].disc_name);
          setTableDisplayStatus(res.data[0].cer_tableDisplayStatus);
          let extraEvvtries = res.data[0].cer_extraColumns
            ? JSON.parse(res.data[0].cer_extraColumns)
            : {};
          setInput(extraEvvtries["input"]);
          extraEvvtries["operating range"] = parseRange(
            extraEvvtries["operating range"]
          );
          setOperatingrand(extraEvvtries["operating range"]);
          setDepartment(extraEvvtries["department"]);
          setExtraColumns(
            Object.keys(extraEvvtries).length
              ? Object.entries(extraEvvtries)
              : []
          );
          setCalibrationReason(res.data[0].cer_calibrationReason);
          setComplianceStatus(res.data[0].cer_complianceStatus);
        }

        fetchCertificateStaticTables(
          res.data[0].cer_instrumentId,
          res.data[0].cer_deletedTableIds
        );

        fetchInstrument(res.data[0].cer_instrumentId);
        if (res.data[0].cer_additionalStandardIds) {
          let standardMasterIds = res.data[0].cer_additionalStandardIds;

          var splitStData = standardMasterIds.split(",");
          var combineArray = [];
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0];
            combineArray.push(splitByCol);
          });
          axiosWithToken
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data);
            });
        } else if (res.data[0].ins_standardMasterIds) {
          var splitStData = res.data[0].ins_standardMasterIds.split(",");
          var combineArray = [];
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0];
            combineArray.push(splitByCol);
          });
          console.log(combineArray.toString());
          axiosWithToken
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data);
              console.log("standards", res.data);
            });
        }
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err);
      });

    axiosWithToken
      .get(
        url +
          `xjoin?_join=cer.certificates,_j,d.datasheets&_on1=(cer.id,eq,d.id)&_fields=cer.approvedby,d.requestedname,d.calibratedby,d.revisionNumber,d.calibrationDate,d.amendment,d.calFrequency&_where=(cer.id,eq, ${certificateId})`
      )
      .then((res) => {
        setApprovedby(
          res.data[0].cer_approvedby ? res.data[0].cer_approvedby : ""
        );
        setCalibratedby(
          res.data[0].d_calibratedby ? res.data[0].d_calibratedby : ""
        );
        setCalibrationFrequency(res.data[0].d_calFrequency);
        setRevisionNumber(res.data[0].d_revisionNumber);
        setCalibrationDate(res.data[0].d_calibrationDate);
        let tmpSplt = res.data[0].d_amendment
          ? res.data[0].d_amendment.split("\n")
          : [];
        setAmendmentHistory(tmpSplt.filter((_, i) => i === tmpSplt.length - 1));
        setRequestedName(
          res.data[0].d_requestedname ? res.data[0].d_requestedname : ""
        );
      });
  }

  function fetchCertificateStaticTables(instrumentId, deletedTableIds) {
    deletedTableIds = deletedTableIds?.split(",")?.map((id) => Number(id));
    axiosWithToken
      .get(
        BASE_URL +
          `certificateStaticTables?_where=(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        if (deletedTableIds) {
          res.data = res.data.filter(
            (row) => !deletedTableIds.includes(row.id)
          );
        }
        setStaticTables(res.data);
      })
      .catch((err) => {
        console.log("datasheet static tables data fetching error: ", err);
      });
  }

  function fetchStaticReadingRows() {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `certificateStaticReadings?_where=(certificateId,eq,${certificateId})`
      )
      .then((res) => {
        setStaticCertificateReadings(res.data);
      })
      .catch((err) => {
        console.log("static certificate tables data fetching error: ", err);
      });
  }

  function fetchInstrument(instrumentId) {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        setInstrument(res.data);
      })
      .catch((err) => {
        console.log("certificate tables data fetching error: ", err);
      });
  }

  const getTableIndex = (tableId, isStatic = false) => {
    if (!isStatic) {
      for (let i = 0; i < tables.length; i++) {
        if (tables[i]["id"] == tableId) {
          return i;
        }
      }
    } else {
      for (let i = 0; i < staticTables.length; i++) {
        if (staticTables[i]["id"] == tableId) {
          return i;
        }
      }
    }
    return -1;
  };

  const initiateStaticReadingRows = () => {
    if (!(staticCertificateReadings && staticTables)) return null;

    let colsToSkip = [
      "id", // avoide duplicate
      "unitType",
      "instrumentId",
      "tableName",
      "parameterName",
      "defaultConfiguration",
      "conditionFormatting",
      "lastModified",
      "status",
      "isUncertainty",
    ];

    let readingRows = [];
    let tempStaticCertReadings = [...staticCertificateReadings];
    let _selectedRangeIds = selectedRangeIds;

    tempStaticCertReadings.forEach((reading, readingIndex) => {
      let tableIndex = getTableIndex(reading.tableId, true);

      if (tableIndex == -1) return null;
      let row = [];
      row.push(staticTables[tableIndex]["id"]);
      row.push(reading["id"]);

      for (let i = 0; i < MAX_COLS; i++) {
        // check for default value
        if (reading["c" + (i + 1)] != null) {
          row.push(
            reading["c" + (i + 1)] === "NA" ? "" : reading["c" + (i + 1)]
          );
        } else {
          break;
        }
      }

      if (
        staticTables[tableIndex]["isUncertainty"] == 1 &&
        !staticTables[tableIndex].isVerticalUncertainty
      ) {
        row.push(reading["uncertainty"] ?? "null");
      }

      readingRows.push(row);

      // set current ranges to selectedRangeIds
      _selectedRangeIds[readingIndex] = reading.standardRanges;

      setTableName(staticTables[tableIndex]["tableName"]);
      setUnitName(staticTables[tableIndex]["unitType"]);
    });
    setStaticReadingRows(readingRows);
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        console.log("Something Went Wrong!");
      });
  };

  const submitCertificate = () => {
    props.setLoader(true);

    let data = {
      certificateNumber: certificateNumber,
      dcNumber: dcNumber,
      workOrderNumber: workOrderNumber,
      serviceReqNumber: serviceReqNumber,
      ULRNo: ulrNumber,
      poNumber: poNo,
      poDate: poDate
        ? moment(poDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      dateOfIssue: dateOfIssue
        ? moment(dateOfIssue).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      calibrationDate: calibrationDate
        ? moment(calibrationDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      nextDueDate: nextDueDate ? moment(nextDueDate).format("YYYY-MM-DD") : "",

      receiptDate: receiptDate
        ? moment(receiptDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      dcDate: dcDate
        ? moment(dcDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    };

    axiosWithToken
      .patch(BASE_URL + "certificates/" + certificateId, data)
      .then((res) => {
        toast.success("Datasheet updated successfully!");
        props.setLoader(false);
      })
      .catch((err) => {
        toast.error("error : " + err);
        props.setLoader(false);
      });
  };

  useEffect(() => {
    initiateStaticReadingRows();
  }, [staticCertificateReadings, staticTables]);

  useEffect(() => {
    getInstrumentsList();
    fetchCertificate();
    fetchStaticReadingRows();
    fetchSettings();

    // if (props.isPrint) {
    //   setTimeout(() => {
    //     setisPrinting(true)
    //   }, 8000)
    // }
  }, []);

  useEffect(() => {
    if (props.isPrint === true) {
      setisPrinting(true);
      setTimeout(() => {
        generatePDF();
      }, 2000);
    }
  }, [props.isPrint]);

  const getTotalUnitRows = (staticReadingRows, tableId) => {
    let count = 0;
    count = staticReadingRows
      ?.filter((e) => e[0] == tableId)
      ?.some((row) => {
        if (row[2]?.includes("_unit_")) count += 1;
        if (count > 1) return true;
      });
    if (count) count = 2;
    else count = 0;
    return count;
  };
  //
  const renderCommonHeader = (defaultConfiguration) => {
    if (!defaultConfiguration) return null;
    defaultConfiguration = JSON.parse(defaultConfiguration);
    let hasCommonHeader = defaultConfiguration.hasCommonHeader;
    if (hasCommonHeader) {
      let commonHeaderConfig =
        defaultConfiguration.commonHeaderConfig?.split(",");
      let cols = [];
      commonHeaderConfig.forEach((col) => {
        let [name, size] = col?.split(":");
        cols.push(
          <BlackBorderTableCell
            style={{ textAlign: "center" }}
            colSpan={Number(size)}
            className={`${name != "$" ? "common-border" : ""}`}
          >
            <b>{name != "$" ? name : ""}</b>
          </BlackBorderTableCell>
        );
      });

      return (
        <TableRow>
          {/* for SN */}
          <BlackBorderTableCell align="center"></BlackBorderTableCell>
          {cols}
        </TableRow>
      );
    }
    return null;
  };

  const renderTableCell = (
    table,
    row,
    rowIndex,
    cell,
    cellIndex,
    current_row_units = null
  ) => {
    let field = null;
    if (String(cell)?.includes("_unit_")) {
      if (table["c" + (cellIndex + 1)]?.toLowerCase()?.match(/m+\d+/g)) {
        field = <div></div>;
      } else {
        let unit = cell.split("_unit_")[1];
        field = (
          <span>
            <b>{unit}</b>
          </span>
        );
      }
    } else if (cell && cell[0] == "$") {
      field = (
        <span>
          {!isNaN(cell.slice(1))
            ? Number(cell.slice(1)).toFixed(2)
            : cell.slice(1)}
        </span>
      );
    } else if (cell && cell == "null") {
      field = (
        <span>
          {!isNaN(cell.slice(1)) ? Number(cell.slice(1)).toFixed(2) : ""}
        </span>
      );
    } else {
      let unit = current_row_units?.[cellIndex + 2]?.split("_unit_")[1];
      if (unit?.length > 0) unit = " " + unit;
      else unit = "";
      field = (
        <span>
          {row[cellIndex + 2]}
          {unit}
        </span>
      );
    }
    return (
      <td
        key={cellIndex}
        className="tableCss"
        style={{
          textAlign: "center",
        }}
      >
        {field}
      </td>
    );
  };
  // render static table
  const renderRows = (table, staticReadingRows) => {
    let i = 1;
    let currentUnitRow = [];

    let totalUnitRows = getTotalUnitRows(staticReadingRows, table.id);

    return [
      ...staticReadingRows?.map((row, rowIndex) => {
        if (row[0] == table.id) {
          if (row[2]?.includes("_rh_")) {
            i = 1;
            return (
              <tr
                key={rowIndex}
                height="14px"
                style={{
                  pageBreakInside: "avoid",
                  pageBreakAfter: "avoid",
                  pageBreakBefore: "avoid",
                }}
              >
                <td
                  className="tableCss"
                  colSpan={row.length - 2 + 1}
                  style={{ textAlign: "center", fontWeight: "400" }}
                >
                  {row[0 + 2].split("_rh_")[1]}
                </td>
              </tr>
            );
          } else if (row[2]?.includes("_unit_")) {
            currentUnitRow = row;
            if (totalUnitRows < 2) {
              return (
                <tr
                  key={rowIndex}
                  height="14px"
                  style={{
                    pageBreakInside: "avoid",
                    pageBreakAfter: "avoid",
                    pageBreakBefore: "avoid",
                  }}
                >
                  <td className="tableCss" style={{ color: "black" }}>
                    <b>Unit</b>
                  </td>
                  {row
                    .slice(2, row.length) // 2: tableId + datasheetId
                    .map((cell, cellIndex) =>
                      renderTableCell(table, row, rowIndex, cell, cellIndex)
                    )}
                </tr>
              );
            } else return null;
          }
          // else normal row
          return (
            <tr
              key={rowIndex}
              height="14px"
              style={{
                pageBreakInside: "avoid",
                pageBreakAfter: "avoid",
                pageBreakBefore: "avoid",
              }}
            >
              <td className="tableCss">{i++}</td>
              {row
                .slice(2, row.length) // 2: tableId + datasheetId
                .map((cell, cellIndex) => {
                  if (totalUnitRows > 1)
                    return renderTableCell(
                      table,
                      row,
                      rowIndex,
                      cell,
                      cellIndex,
                      currentUnitRow
                    );
                  else
                    return renderTableCell(
                      table,
                      row,
                      rowIndex,
                      cell,
                      cellIndex
                    );
                })}
            </tr>
          );
        }
      }),
    ].concat(
      table.isUncertainty == 1 &&
        table.isVerticalUncertainty == 1 &&
        staticReadingRows &&
        staticReadingRows.length > 0
        ? [
            <tr
              key={staticReadingRows.length}
              height="14px"
              style={{
                pageBreakInside: "avoid",
                pageBreakAfter: "avoid",
                pageBreakBefore: "avoid",
              }}
            >
              <td
                className="tableCss text-align-left"
                colSpan={staticReadingRows[0].length - 2 + 1}
                style={{ fontWeight: "400" }}
              >
                <b>Expanded Uncertainty ± : </b>
                {staticCertificateReadings.reduce(
                  (a, b) =>
                    Math.max(
                      parseFloat(a.uncertainty) ? parseFloat(a.uncertainty) : 0,
                      parseFloat(b.uncertainty) ? parseFloat(b.uncertainty) : 0
                    ),
                  -Infinity
                )}
              </td>
            </tr>,
          ]
        : []
    );
  };
  const renderStaticTables = () => {
    if (!staticTables) return null;

    let validTables = staticTables
      .filter((table) => table.status == 1)
      .map((table, tableIndex) => {
        if (tableDisplayStatus === 1 && table.isAutomated !== 0) return null;
        else if (tableDisplayStatus === 2 && table.isAutomated !== 1)
          return null;

        return table;
      })
      .filter((t) => t !== null);

    let split1 = 0;
    let second = false;
    return validTables.map((table, tableIndex) => {
      let i = 0;

      let resd = tableIndex % 2;

      let col = second ? 6 : 12;
      if (second) second = false;
      else if (resd === split1) {
        if (tableIndex !== validTables.length - 1) {
          let len =
            Array.from({ length: MAX_COLS + 1 }, (_, i) => {
              if (validTables[tableIndex]["c" + i] != null) {
                return true;
              }
            }).filter((e) => e === true).length +
            1 +
            (validTables[tableIndex]["isUncertainty"] == 1 ? 1 : 0) +
            Array.from({ length: MAX_COLS + 1 }, (_, i) => {
              if (validTables[tableIndex + 1]["c" + i] != null) {
                return true;
              }
            }).filter((e) => e === true).length +
            1 +
            (validTables[tableIndex + 1]["isUncertainty"] == 1 ? 1 : 0);

          if (len < 9) {
            second = true;
            col = 6;
          } else {
            split1 += 1;
            split1 %= 2;
          }
        }
      }

      let showHeader = JSON.parse(table?.defaultConfiguration)?.showHeader;
      showHeader = showHeader === undefined ? true : showHeader;

      return (
        <div
          key={tableIndex}
          align="left"
          style={{ marginBottom: "3px" }}
          className={col === 6 ? "col-6" : "col-12"}
        >
          <b className="standardUnitTable">
            {configuration?.customTableNames?.[table.id] || table.tableName}
          </b>
          <div style={{ width: "100%", overflow: "auto" }}>
            <ClassicTable>
              <table
                style={{ padding: "0px" }}
                // sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="single-border-table"
              >
                {showHeader && renderCommonHeader(table?.defaultConfiguration)}

                {showHeader ? (
                  <tr
                    style={{
                      pageBreakInside: "avoid",
                      pageBreakAfter: "avoid",
                      pageBreakBefore: "avoid",
                    }}
                  >
                    <td
                      className="header-border tableCss header-border-top-none common-header"
                      align="center"
                    >
                      <b className="standardUnitTable">SR NO.</b>
                    </td>
                    {/* render columns*/}
                    {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                      if (table["c" + i] != null) {
                        return (
                          <td
                            className="header-border tableCss header-border-top-none common-header"
                            align="center"
                            style={{ textAlign: "center" }}
                          >
                            <b>{table["c" + i]}</b>
                          </td>
                        );
                      }
                    })}
                    {table["isUncertainty"] == 1 &&
                      !staticTables[tableIndex].isVerticalUncertainty && (
                        <td
                          className="header-border tableCss black header-border-top-none common-header"
                          align="center"
                          // style={{ textAlign: 'center', fontWeight: '600', width: "100px" }}
                          style={{ textAlign: "center", width: "150px" }}
                        >
                          <b>
                            {
                              "Expanded Uncertainty @ 95% confidence level (𝑘=2)"
                            }
                          </b>
                          {/* </Typography> */}
                        </td>
                      )}
                  </tr>
                ) : (
                  ""
                )}
                {renderRows(table, staticReadingRows, tableIndex)}
              </table>
            </ClassicTable>
          </div>
        </div>
      );
    });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  function changeCertificateStatus(ids) {
    props.setLoader(true);
    let url = BASE_URL;
    if (ids === 3) {
      axiosWithToken
        .patch(url + `certificates/${id}`, { status: 3, approvedby: user[1] })
        .then((res) => {
          toast.success("Certificates are approved successfully!");
          props.setLoader(false);
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axiosWithToken
        .patch(url + `certificates/${id}`, { status: 4, approvedby: user[1] })
        .then((res) => {
          toast("Certificates are rejected successfully!");
          props.setLoader(false);
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  }

  function spaceStr(n) {
    return " ".repeat(n);
  }

  const generateDynamicHeader = async (
    ulrNumber,
    certificateNumber,
    pageNo,
    totalPages
  ) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div style="padding:30px;">
      <table style="width:100%;height: 30px;">
        <tr>
          ${
            ulrNumber
              ? `<td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Url No </b> <br />
            <span style="font-size:19px;">${ulrNumber}</span>
          </td>`
              : ""
          }
          <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Certificate No</b>
            <br>
            <span style="font-size:19px;">${certificateNumber}</span>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Page Number</b>
            <br>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateDynamicFooter = async (
    calibratedby,
    approvedby,
    calibrationDate
  ) => {
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
        <tr>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">CALIBRATED BY <br> Electronically signed by </b>
                <br>
                <span style="font-size:19px;">${calibratedby}<br>${moment(
                    calibrationDate
                  ).format("DD/MM/YYYY")}</span>
            </td>`
                : `<td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;vertical-align: top;">CALIBRATED BY</b>
                <br>
                <span style="font-size:19px;">${calibratedby}</span>
            </td>`
            }
            </td>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">CHCECKED AND APPROVED BY <br> Electronically signed by </b> <br>
                <span style="font-size:19px;">${approvedby}<br>${moment(
                    calibrationDate
                  ).format("DD/MM/YYYY")}</span>`
                : `
            <td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:17px;vertical-align: top;">CHCECKED AND APPROVED BY </b>
                <br>
                <span style="font-size:19px;">${approvedby}</span>
                `
            }
            </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [140, 1, 165, 1],
        filename: `certificate_${certificateId}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      let image = await generateDynamicHeader(
        ulrNumber,
        certificateNumber,
        i,
        totalPages
      );
      pdf.addImage(image, 0, 70, 595, 80);

      image = await generateDynamicFooter(
        calibratedby,
        approvedby,
        calibrationDate
      );
      pdf.addImage(image, 0, 675, 595, 100);

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 772, 595, 70);
      }
    }

    pdf.save();
    countOfCertificatesAnalytics([certificateId])
  };

  // print view
  const printView = () => {
    return (
      <>
        <div id="printOnly2" ref={printComponentRef}>
          <div style={{ width: "100%" }} id="header-section"></div>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ border: "none" }}>
                  <div
                    class="content"
                    style={{
                      fontSize: "11px",
                      margin: "20px",
                      marginTop: "0px",
                      marginBottom: "0px",
                      padding: "10px",
                      border: "1px solid black",
                    }}
                  >
                    <h5 style={{ textAlign: "center", marginBottom: "5px" }}>
                      <b>
                        <u>CALIBRATION CERTIFICATE</u>
                      </b>
                    </h5>
                    <Table>
                      {customerDetailsTable()}
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <b>DETAILS OF DEVICE UNDER CALIBRATION (DUC)</b>
                        </TableCell>
                      </TableRow>
                      {unitCalibrationDetails()}
                      <Table
                        className={classes.table}
                        style={{ marginBottom: "3px" }}
                      >
                        <TableRow>
                          <TableCell>
                            <b>Environmental Conditions: </b>
                          </TableCell>
                          <TableCell>
                            <span>
                              <b style={{ paddingRight: "10px" }}>
                                Temperature :
                              </b>
                              {startTemp} C°
                            </span>
                          </TableCell>
                          <TableCell>
                            <span>
                              <b style={{ paddingRight: "10px" }}>Humidity:</b>
                              {startHumidity} %RH
                            </span>
                          </TableCell>
                        </TableRow>
                      </Table>
                      {masterDataTable()}
                    </Table>
                    <div>
                      <div style={{ textAlign: "left" }}>
                        <div style={{ marginBottom: "5px", marginTop: "10px" }}>
                          <b>DISCIPLINE : </b>
                          {disciplineName}
                        </div>
                        <div className="row">{renderStaticTables()}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              border: "1px solid black",
                              padding: "5px",
                              flex: "1",
                            }}
                            className="break-remark"
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <div style={{ float: "left" }}>
                                  <b
                                    style={{ float: "left", fontSize: "11px" }}
                                    className="standardUnitTable"
                                  >
                                    INFERENCE :
                                  </b>
                                </div>
                              </div>
                            </div>
                            <div className="text-start" style={{}}>
                              <pre
                                style={{
                                  fontFamily: " Calibri, sans-serif",
                                  fontSize: "10px",
                                  marginBottom: "0px",
                                  lineHeight: 1,
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {compliance
                                  ? `The results ${
                                      complianceStatus == 1 ? "" : "doesn't"
                                    } comply with acceptance criteria
                                  \nReason For Calibration : ${
                                    calibrationReason == 1 ? "New" : "Periodic"
                                  }\n`
                                : ""}
                              {`${
                                configuration?.remark || defaultReamrk
                              }\n${amendmentHistory}`}
                              {/* {
                                  (settingList["lastModified"] && settingList["lastModified"  ].toLowerCase() === "true") ?
                                    `\nPrint At : ${moment(new Date()).format("mm/DD/yyyy")}` : ""
                                    // `\nLast Modified : ${moment(lastModified).format("mm/DD/yyyy")}` : ""
                                } */}
                              </pre>
                            </div>
                          </div>
                          <div
                            style={{ textAlignLast: "center", padding: "10px" }}
                          >
                            <QRCode
                              value={`${DOMAIN}/#/certificate/print/${certificateId}`}
                              align="center"
                              size={60}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      --------- END OF CALIBRATION CERTIFICATE ---------
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {settingList["lastModified"] &&
                      settingList["lastModified"].toLowerCase() === "true"
                        ? `${moment(new Date()).format("DD/MM/yyyy HH:mm:SS")}`
                        : ""}
                    </div>
                    {/* <div className="row mt-2">
                      
                    </div> */}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
    function customerDetailsTable() {
      return (
        <Table className={classes.table} style={{ marginBottom: "3px" }}>
          <TableRow className={classes.row}>
            <TableCell className={classes.addressCell} style={{ width: "25%" }}>
              <div>
                <b>Customer Name & Address</b>
              </div>
            </TableCell>
            <TableCell
              style={{
                width: "25%",
                borderLeft: "1px solid black",
                padding: "3px",
              }}
            >
              <div>
                <b>{clientId}</b>
              </div>
              <div>{address}</div>
            </TableCell>
            <TableCell
              style={{
                width: "25%",
                borderLeft: "1px solid black",
                padding: 0,
              }}
            >
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                <b>Date of Receipt</b>
              </div>
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                <b>Calibration Date</b>
              </div>
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                <b>Sugg. Due Date</b>
              </div>
              <div style={{ padding: "3px" }}>
                <b>Date of Issue</b>
              </div>
            </TableCell>
            <TableCell
              style={{
                width: "25%",
                borderLeft: "1px solid black",
                padding: 0,
              }}
            >
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                {moment(receiptDate).format("DD-MM-YYYY")}
              </div>
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                {moment(calibrationDate).format("DD-MM-YYYY")}
              </div>
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                {moment(nextDueDate).format("DD-MM-YYYY")}
              </div>
              <div style={{ padding: "3px" }}>
                {moment(dateOfIssue).format("DD-MM-YYYY")}
              </div>
            </TableCell>
          </TableRow>
        </Table>
      );
    }
    function unitCalibrationDetails() {
      return (
        <Table
          className={classes.table}
          style={{ fontSize: "11px", marginBottom: "3px" }}
        >
          <TableRow className={classes.row}>
            <TableCell className={classes.infoCell} align="center">
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Name of Instrument </b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {requestedName.length > 0 ? requestedName : instrumentName}
              </TableCell>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>SRF No.</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {serviceReqNumber}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Make / Model</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell
                  style={{ border: "none" }}
                >{`${make}/${models}`}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Serial no. / Identification No.</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell
                  style={{ border: "none" }}
                >{`${DUCID} / ${serialNo}`}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Range</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{ranges}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Accuracy</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {accuracy
                    ? `± ${accuracy
                        ?.replaceAll("||", ", ")
                        ?.replaceAll("|", " to ")
                        ?.replaceAll("#", " ")}`
                    : ""}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Resolution/Least Count</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {lc
                    ?.replaceAll("||", ", ")
                    ?.replaceAll("|", " to ")
                    ?.replaceAll("#", " ")}
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Location</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {locationOfInstrument ? locationOfInstrument : ""}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Calibration Location </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{location}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Condition of Instrument </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{ConOfDuc}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Calibration Procedure</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {calibrationProcedureNo}
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Standard Reference</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {referenceStandards}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
        </Table>
      );
    }
    function masterDataTable() {
      //Please don't put thead or tbody
      return (
        <div align="center">
          <b>STANDARDS & MAJOR EQUIPMENTS USED FOR CALIBRATION</b>
          <br></br>
          <table
            className="standardUnitTable single-border-table"
            align="center"
          >
            <tr>
              <td>
                <b>Standard Used</b>
              </td>
              <td>
                <b>Range/L.C.</b>
              </td>
              <td>
                <b>Limit of Accuracy / Uncertainty in meas.</b>
              </td>
              <td>
                <b>Traceability / Validity</b>
              </td>
            </tr>
            {standardMasterArray.map((row, index) => (
              <tr key={row.name}>
                <td>{row.standardName}</td>
                <td>
                  {`${row.masterrange
                    ?.replaceAll("||", ", ")
                    ?.replaceAll("|", " to ")
                    ?.replaceAll("#", " ")} /
                        ${row.masterleastcount
                          ?.replaceAll("||", ", ")
                          ?.replaceAll("|", " to ")
                          ?.replaceAll("#", " ")}`}
                </td>
                <td>
                  {`± ${row.masteraccuracy
                    ?.replaceAll("||", ", ")
                    ?.replaceAll("|", " to ")
                    .replaceAll("±", "")}`?.replaceAll("#", " ")}
                </td>
                <td>{`${row.certificateNumber} / ${moment(row.validUpto).format(
                  "DD/MM/YYYY"
                )}`}</td>
              </tr>
            ))}
          </table>
        </div>
        // <ClassicTable className="textfield">
        //   <Table sx={{ minWidth: 650 }} aria-label="simple table">
        //     <TableHead>
        //       <TableRow>
        //         <TableCell>Standard(s)</TableCell>
        //         <TableCell>Range Laestcount</TableCell>
        //         <TableCell>Lmit of Accuracy / Uncertainty in means.</TableCell>
        //         <TableCell>Tracability / Validity</TableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody>
        //       {standardMasterArray
        //         .filter((standard) =>
        //           selectedStandards?.includes(String(standard.id))
        //         )
        //         ?.map((row, index) => (
        //           <TableRow>
        //             <TableCell>{row.standardName}</TableCell>
        //             <TableCell>
        //               {`${row.masterrange
        //                 ?.replaceAll("||", ", ")
        //                 ?.replaceAll("|", " to ")
        //                 ?.replaceAll("#", " ")} /
        //                 ${row.masterleastcount
        //                   ?.replaceAll("||", ", ")
        //                   ?.replaceAll("|", " to ")
        //                   ?.replaceAll("#", " ")}`}
        //             </TableCell>
        //             <TableCell>
        //               {`± ${row.masteraccuracy
        //                 ?.replaceAll("||", ", ")
        //                 ?.replaceAll("|", " to ")
        //                 .replaceAll("±", "")}`?.replaceAll("#", " ")}
        //             </TableCell>
        //             <TableCell>{row.traceability}</TableCell>
        //           </TableRow>
        //         ))}
        //     </TableBody>
        //   </Table>
        // </ClassicTable>
      );
    }
  };

  return (
    <>
      {!isPrinting && (
        <div id="screenOnly" sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Customer Name"
                className="textfield"
                size="small"
                value={clientId}
                disabled
                fullWidth
                variant="outlined"
                onChange={(e) => setClientId(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Work order number"
                className="textfield"
                size="small"
                value={workOrderNumber}
                onChange={(e) => setWorkOrderNumber(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="ULR Number"
                className="textfield"
                size="small"
                value={ulrNumber}
                onChange={(e) => setUlrNumber(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Certificate Number"
                className="textfield"
                size="small"
                value={`${certificateNumber}${
                  settingList["amendment"] === "true" && revisionNumber > 0
                    ? `/AMD-${revisionNumber}`
                    : ""
                }`}
                onChange={(e) => setCertificateNumber(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Address"
                className="textfield"
                value={address}
                InputLabelProps={{ shrink: true }}
                disabled
                onChange={(e) => setAddress(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                InputLabelProps={{ shrink: true }}
                label="PO Number"
                className="textfield"
                size="small"
                value={poNo}
                onChange={(e) => setPoNo(e.target.value)}
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>

            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="PO Date"
                  inputFormat="MM/dd/yyyy"
                  InputLabelProps={{ shrink: true }}
                  value={poDate ? new Date(poDate) : ""}
                  onChange={(e) => setPoDate(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                  disabled
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Service Request No."
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                value={serviceReqNumber}
                onChange={(e) => setServiceReqNumber(e.target.value)}
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Job Number."
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                value={jobNumber}
                onChange={(e) => setJobNumber(e.target.value)}
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="DC Number"
                className="textfield"
                size="small"
                value={dcNumber}
                onChange={(e) => setDcNumber(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date Of Issue"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={dateOfIssue ? new Date(dateOfIssue) : ""}
                  onChange={(e) => setDateOfIssue(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                  disabled
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date of calibration"
                  InputLabelProps={{ shrink: true }}
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={calibrationDate ? new Date(calibrationDate) : ""}
                  onChange={(e) => setCalibrationDate(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                  disabled
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Next due date"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={nextDueDate ? new Date(nextDueDate) : new Date()}
                  onChange={(e) => setNextDueDate(e)}
                  InputLabelProps={{ shrink: true }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                  disabled
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date of receipt"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={receiptDate ? new Date(receiptDate) : ""}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setReceiptDate(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                  disabled
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date of DC"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={dcDate ? new Date(dcDate) : ""}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setDcDate(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                  disabled
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <hr className="textfield" />
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Instrument Name"
                disabled
                size="small"
                className="textfield"
                InputLabelProps={{ shrink: true }}
                value={instrumentName}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Requested Name"
                size="small"
                disabled
                value={requestedName}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Calibration Procedure No."
                value={calibrationProcedureNo}
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Referencer Standards"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={referenceStandards}
                className="textfield"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="DUC ID"
                value={DUCID}
                InputLabelProps={{ shrink: true }}
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Make"
                value={make}
                className="textfield"
                onChange={(e) => setMake(e.target.value)}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Model"
                value={models}
                className="textfield"
                onChange={(e) => setModel(e.target.value)}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Con Of Duc"
                value={ConOfDuc}
                className="textfield"
                onChange={(e) => setConOfDuc(e.target.value)}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Range"
                value={ranges}
                onChange={(e) => setRanges(e.target.value)}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                className="textfield"
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="LC"
                value={lc
                  ?.replaceAll("||", ", ")
                  ?.replaceAll("|", " to ")
                  ?.replaceAll("#", " ")}
                onChange={(e) => setLC(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Serial Number"
                value={serialNo}
                onChange={(e) => setSerialNo(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Accuracy"
                value={accuracy
                  ?.replaceAll("||", ", ")
                  ?.replaceAll("|", " to ")
                  ?.replaceAll("#", " ")}
                onChange={(e) => setAccuracy(e.target.value)}
                className="textfield"
                InputLabelProps={{ shrink: true }}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Cal Point"
                value={calPoint}
                disabled
                className="textfield"
                onChange={(e) => setCalPoint(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Cal Method"
                value={calMethod}
                disabled
                className="textfield"
                onChange={(e) => setCalMethod(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Location Of Instrument"
                value={locationOfInstrument}
                disabled
                className="textfield"
                onChange={(e) => setLocationOfInstrument(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ my: 2 }}>
            {extraColumns.map((col) => (
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  label={col[0]}
                  value={col[1]}
                  // onChange={(e) => setStartTemp(e.target.value)}
                  size="small"
                  fullWidth
                  variant="outlined"
                  disabled
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                InputLabelProps={{ shrink: true }}
                label="Start Temperature"
                value={startTemp}
                size="small"
                className="textfield"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                label="End temprature"
                value={endTemp}
                onChange={(e) => setEndTemp(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                label="Start Humidity"
                value={startHumidity}
                onChange={(e) => setStartHumidity(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                label="End Humidity"
                value={endHumidity}
                onChange={(e) => setEndHumidity(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Atmospheric Pressure"
                value={atmosphericPressure}
                onChange={(e) => setAtmosphericPressure(e.target.value)}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Discipline"
                disabled
                value={disciplineName}
                size="small"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>

          <hr />
          <div>
            <Typography
              variant="h6"
              className="textfield"
              component="div"
              sx={{ mb: 2 }}
            >
              Master Equipments Used
            </Typography>
            <ClassicTable className="textfield">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No.</TableCell>
                    <TableCell>Name of Standard</TableCell>
                    <TableCell>Range</TableCell>
                    <TableCell>Accuracy</TableCell>
                    <TableCell>Least Count</TableCell>
                    <TableCell>Make/Model</TableCell>
                    <TableCell>Certificate No.</TableCell>
                    <TableCell>Tracability</TableCell>
                    <TableCell>Cal.dt</TableCell>
                    <TableCell>Due Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {standardMasterArray
                    .filter((standard) =>
                      selectedStandards?.includes(String(standard.id))
                    )
                    ?.map((row, index) => (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.standardName}</TableCell>
                        <TableCell>
                          {row.masterrange
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </TableCell>
                        <TableCell>
                          {`± ${row.masteraccuracy
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            .replaceAll("±", "")}`?.replaceAll("#", " ")}
                        </TableCell>
                        <TableCell>
                          {row.masterleastcount
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </TableCell>
                        <TableCell>{row.make}</TableCell>
                        <TableCell>{row.certificateNumber}</TableCell>
                        <TableCell>{row.traceability}</TableCell>
                        <TableCell>
                          {moment(row.calDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          {moment(row.validUpto).format("DD/MM/YYYY")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </ClassicTable>
          </div>
          <br />
          <hr className="textfield" />
          <div className="textfield">
            <Typography variant="h6" className="textfield" component="div">
              Observed Readings
            </Typography>

            {renderStaticTables()}
          </div>
          <div className="row">
            <div className="col-md-6">
              <div>
                <h5 style={{ float: "left" }}>Remarks :</h5>

                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={5}
                  placeholder="Enter remark here"
                  style={{
                    width: 900,
                    float: "left",
                    border: "none",
                    fontSize: "11px",
                  }}
                  value={`${
                    compliance
                      ? `The results ${
                          complianceStatus == 1 ? "" : "doesn't"
                        } comply with acceptance criteria\nReason For Calibration : ${
                          calibrationReason == 1 ? "New" : "Periodic"
                        }\n`
                      : ""
                  }
                  ${`${
                    configuration?.remark || defaultReamrk
                  }\n${amendmentHistory}`}`}
                  disabled
                />
              </div>
            </div>
          </div>
          <div>
            {/* <Grid container spacing={2}>
            <Grid item>
              <b>Last Modified : </b>
            </Grid>
            <Grid item>
              {(settingList["lastModified"] && settingList["lastModified"  ].toLowerCase() === "true") ? moment(lastModified).format("mm/DD/yyyy") : ""}
            </Grid>
          </Grid> */}
          </div>
          <hr className="textfield" />
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Calibrated by"
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
                value={calibratedby}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                className="textfield"
                label="Checked / Approved by"
                size="small"
                fullWidth
                variant="outlined"
                value={approvedby}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Issue Number"
                size="small"
                className="textfield"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Rev. Number"
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
                disabled
                value={`${certificateNumber}${
                  settingList["amendment"] === "true" && revisionNumber > 0
                    ? `/${revisionNumber}`
                    : ""
                }`}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                label="Form Number"
                className="textfield"
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Issue Date"
                  inputFormat="MM/dd/yyyy"
                  onChange={(e) => {
                    // console.log(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                  disabled
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Revision Date"
                  inputFormat="MM/dd/yyyy"
                  onChange={(e) => {
                    // console.log(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="textfield"
                      size="small"
                      fullWidth
                    />
                  )}
                  disabled
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </div>
      )}
      <Toolbar style={{ padding: "0px", width: "100%" }}>
        {userType != 3 && !isPrinting && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              submitCertificate();
            }}
          >
            Save
          </Button>
        )}
        {calibratedby &&
          calibratedby.length > 0 &&
          calibratedby !== user[1] &&
          userType != 3 &&
          status !== -1 &&
          status != 3 &&
          !isPrinting && (
            <Button
              variant="contained"
              color="success"
              size="small"
              sx={{ ml: 3 }}
              onClick={() => {
                changeCertificateStatus(3);
              }}
            >
              Approve
            </Button>
          )}
        {calibratedby &&
          calibratedby.length > 0 &&
          calibratedby !== user[1] &&
          userType != 3 &&
          status !== -1 &&
          status != 3 &&
          status != 4 &&
          !isPrinting && (
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ ml: 3 }}
              onClick={() => {
                changeCertificateStatus(4);
              }}
            >
              Reject
            </Button>
          )}
        {!isPrinting && (
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              setisPrinting(true);
            }}
          >
            Print
          </Button>
        )}

        {!props.isPrint && isPrinting && !props.certificateIds && (
          <Grid container spacing={2} align="left" style={{ display: "flex" }}>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "With Letter Head" },
                  { id: 2, label: "Without Letter Head" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="letterHead" />
                )}
                onInputChange={(event, newInputValue) => {
                  if (
                    newInputValue === "With Letter Head" ||
                    newInputValue === ""
                  ) {
                    setLetterHead(true);
                  } else if (newInputValue === "Without Letter Head") {
                    setLetterHead(false);
                  }
                  // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "With Electronically Signed" },
                  { id: 2, label: "Without  Electronically Signed" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Electronically Signed" />
                )}
                onInputChange={(event, newInputValue) => {
                  console.log("newValue : ", newInputValue);
                  if (
                    newInputValue === "With Electronically Signed" ||
                    newInputValue === ""
                  ) {
                    setElectronicSign(true);
                  } else if (
                    newInputValue === "Without  Electronically Signed"
                  ) {
                    setElectronicSign(false);
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              {isPrinting && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ ml: 3 }}
                  onClick={() => {
                    generatePDF();
                  }}
                >
                  Download PDF
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Toolbar>

      {isPrinting && printView()}
      {/* {isPrinting && printViewClient()} */}
    </>
  );
}
